<template>
    <div class="support-pattern">
        <div class="row">
            <div class="col d-flex justify-end flex-row flex-nowrap align-items-center">
                <button class="btn btn-primary mr-2" :disabled="isCheckButtonCopy" @click="getDetail(null, 'Copy')"><i class="fas fa-plus"></i> 複製</button>
                <button class="btn btn-primary" @click="getDetail(null, 'Add')"><i class="fas fa-plus"></i> 追加</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <v-data-table :headers="headers" :items="lists" disable-pagination hide-default-footer
                    class="data-table table table-responsive table-hover" id="tableHighlight">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id" :id="'highlight' + item.id"
                                @click="changeCellHighlight(item.id)">
                                <td>
                                    <span v-if="item.disable_flg">◯</span>
                                </td>
                                <td>
                                    <a @click="getDetail(item, 'Detail')" class="text-primary">
                                        {{ item.support_pattern_name }}
                                    </a>
                                </td>
                                <td>{{ item.formatTheSupportContent }}</td>
                                <td>{{ item.support_pattern_desc }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <Pagination :total-page="totalPage" :current-page="currentPage"
                    @onHandleChangePage="onHandleChangePage" />
            </div>
        </div>
        <Slide>
            <Detail v-if="component === 'Add'" pageMode="NEW" :dataDetail="null" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
            <Detail v-if="component === 'Copy'" pageMode="NEW" :dataDetail="itemCopy" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
            <Detail v-if="component === 'Detail'" pageMode="EDIT" :dataDetail="dataDetail" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
        </Slide>
    </div>
</template>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        &.disable_flg {
            min-width: 80px !important;
        }
        &.support_pattern_name {
            min-width: 200px !important;
        }
        &.support_pattern {
            min-width: 450px !important;
        }
    }
</style>
<script>

import Detail from "./support-pattern-detail"
import patternManagement from "@/services/api/patternManagement";
import { SUCCESS } from "@/constants";
import { mapMutations } from "vuex";
import Slide from "@/components/Slide";
import mixins from "@/mixins";
import _ from "lodash";
import Pagination from "@/components/common/Pagination";

export default {
    name: "Support-Pattern",

    mixins: [mixins],

    components: {
        Slide,
        Pagination,
        Detail,
    },

    data() {
        return {
            dataDetail: null,
            component: null,
            totalPage: 1,
            currentPage: 1,
            perPage: 10,
            headers: [
                { text: "無効", align: "start", sortable: true, value: "disable_flg", class: "disable_flg" },
                { text: "支援パターン名", sortable: true, value: "support_pattern_name", class: "support_pattern_name" },
                { text: "支援内容", sortable: true, value: "formatTheSupportContent", class: "support_pattern" },
                { text: "説明", sortable: true, value: "support_pattern_desc" }
            ],
            lists: [],
            itemCopy: null,
            randomString: "",
            isCheckButtonCopy: true,
        }
    },

    async created() {
        await this.getLists();
    },

    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),

        changeCellHighlight(id) {
            this.isCheckButtonCopy = false;
            this.itemCopy = this.lists.filter(x => x.id == id)[0];
            $("#tableHighlight").find(".cellHighlight").removeClass("cellHighlight");
            $("#highlight" + id).addClass("cellHighlight");
        },

        getLists() {
            this.setIsLoading(true)
            patternManagement.getListPattern(this.currentPage, this.perPage)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const { data, status, error } = res;
                    this.setIsLoading(false)
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
	                    return;
                    }
                    this.totalPage = data.data.last_page;
                    this.currentPage = data.data.current_page;

                    this.lists = data.data.data;
                })
        },

        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getLists();
        },

        getDetail(item, component) {
            this.dataDetail = item;
            this.component = component;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
            this.$store.commit("showSlide");
        },

        reload(param) {
            this.changeCellHighlight(null);
            this.isCheckButtonCopy = true;
            this.component = null;
            if (param) {
                this.currentPage = 1;
                this.getLists();
            }
        }
    }
}

</script>
<style scoped lang="sass">
    table
        & tr
            & td, & th  
                line-height: 30px
                padding-left: 5px
                padding-right: 5px
        
    .cellHighlight
        background-color: #d2e3fb
</style>