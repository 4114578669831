import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_RECEPTION, CODE_FRACTION } from "@/common/constants";

export default {
    async getListPattern(page = 1, limit = 10) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/health-support-patterns/list`,{page, limit})
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async savePattern(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/health-support-patterns/save`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async editPattern(id, data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/health-support-patterns/${id}/edit`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async deletePattern(id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/health-support-patterns/${id}/delete`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async detailPattern(id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/health-support-patterns/${id}/detail-support-pattern`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getIntendDateList() {
        try {
            return await Repository.get("/support-flow/intend-date-list")
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getContinueSupportFormList(type = "") {
        try {
            return await Repository.get(`/support-flow/continue-support-form-list/${type}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
