<template>
    <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
        <div class="support-pattern-modify">
            <div v-if="dataEdit" class="tab-eligible-person-information">
                <div class="row">
                    <div class="col-5">
                        <div class="point-title d-flex justify-space-between">
                            <div class="end-point">支援満了ポイント: {{ dataEdit.requried_support_point }} pt</div>
                            <div class="total-point">支援計画合計ポイント(プロセス評価): {{ dataEdit.plan_support_point }} pt</div>
                        </div>
                        <div class="point-detail d-flex justify-content-end">
                            <div class="block-point">
                                <div v-if="supportPatternSummary.supportA?.count > 0">支援A {{supportPatternSummary.supportA?.count}}回 : {{supportPatternSummary.supportA?.point}} pt</div>
                                <div v-if="supportPatternSummary.supportB?.count > 0">支援B {{supportPatternSummary.supportB?.count}}回 : {{supportPatternSummary.supportB?.point}} pt</div>
                                <div v-if="supportPatternSummary.support?.count > 0">支援 {{supportPatternSummary.support?.count}}回 : {{supportPatternSummary.support?.point}} pt</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-7 d-flex justify-content-end align-center pr-5">
                    <div class="d-flex justify-space-end align-center">
                      <button v-if="isPageMode_Edit()" class="btn btn-danger" @click="showDialogEditDelete = true"><v-icon color="white">mdi-trash-can-outline</v-icon> 削除</button>
                    </div>
                </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="float-right">
                                <div class="d-flex">
                                    <div style="width:300px">登録情報</div>
                                    <div style="width:300px">更新情報</div>
                                </div>
                                <div class="d-flex">
                                    <Sheet width="300" height="40" class="p-2">
                                        {{ formatDate(dataEdit.created_at) }} <span v-if="dataEdit.created_by">by {{ String(dataEdit.created_by).padStart(7, '0') }}</span>
                                    </Sheet>
                                    <Sheet width="300" height="40" class="p-2">
                                        {{ formatDate(dataEdit.updated_at) }} <span v-if="dataEdit.updated_by">by {{ String(dataEdit.updated_by).padStart(7, '0') }}</span>
                                    </Sheet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="support-pattern-info">
                    <div class="row d-flex align-items-center">
                        <div class="col-1">
                            <div class="form-check">
                                <input v-model="dataEdit.disable_flg" type="checkbox" class="form-check-input" id="invalid">
                                <label for="invalid" class="form-check-label">無効</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <span class="required">表示順</span>
                            <input v-model="dataEdit.disp_seq" type="number" class="form-control" min="0" max="999">
                            <span class="text-danger">{{ errors.disp_seq }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <span class="required">支援パターン名</span>
                            <input v-model="dataEdit.support_pattern_name" type="text" class="form-control">
                            <span class="text-danger">{{ errors.support_pattern_name }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <span class="required">支援満了ポイント</span>
                            <input v-model="dataEdit.requried_support_point" type="number" class="form-control" min="0" max="999">
                            <span class="text-danger">{{ errors.requried_support_point }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span>支援パターン説明</span>
                            <textarea v-model="dataEdit.support_pattern_desc" rows="4" class="form-control"></textarea>
                            <span class="text-danger">{{ errors.support_pattern_desc }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span>支援パターン</span>
                    </div>
                    <span class="text-danger">{{ errors.health_support_pattern_item }}</span>
                </div>
                <div class="row">
                    <div class="block mt-2">
                        <div class="title-block d-flex justify-content-between bg-first-support bordered pl-2">
                            <div class="title-name">
                                <span></span>初回支援
                            </div>
                            <div class="option">
                                <div class="position-relative">
                                    <div class="dropdown-btn nav-link dropdown-toggle" data-bs-placement="top"
                                        data-bs-toggle="dropdown" aria-expanded="false"></div>
                                    <ul class="dropdown-menu" aria-labelledby="select">
                                        <li class="dropdown-item" @click="add(0)">後続支援追加</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(item, index) in dataEdit.health_support_pattern_item" :key="item.id+'health_support_pattern_item'+index" class="block mt-2">
                        <div class="title-block d-flex justify-content-between bg-continue-support bordered">
                            <div class="title-name">
                                <!-- 支援形態区分(支援A or 支援B) -->
                                <select v-model="item.health_support_form.support_form_div" @change="handleChangeSupportFormDiv(item)" class="form-select p-0 pl-2 pr-13 no-radius">
                                    <option :value="codeStatusSupportA">支援A (第3期)</option>
                                    <option :value="codeStatusSupportB">支援B (第3期)</option>
                                    <option :value="codeStatusSupportContinuing">支援 (第4期)</option>
                                </select>
                            </div>
                            <div class="option">
                                <div class="position-relative">
                                    <div class="dropdown-btn nav-link dropdown-toggle" data-bs-placement="top"
                                        data-bs-toggle="dropdown" aria-expanded="false"></div>
                                    <ul class="dropdown-menu" aria-labelledby="select">
                                        <li class="dropdown-item" @click="add(index + 1)">後続支援追加</li>
                                        <li class="dropdown-item" @click="deleteSupportPatternItem(item, index)">削除</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="content-block bordered no-border-top p-2">
                            <div class="row">
                                 <!-- 予定時期 -->
                                <div class="col-3 d-flex align-items-center">
                                    <label for="scheduled-time" class="text-nowrap mr-2">予定時期</label>
                                    <select name="" class="form-select" v-model="item.scheduled_support_term">
                                        <option v-for="(text, key) in intendDateList" :value="key" :key="key">{{text}}</option>
                                    </select>
                                </div>
                                <!-- 支援形態 -->
                                <div class="col-3 d-flex align-items-center">
                                    <label for="support-form" class="text-nowrap mr-2">支援形態</label>
                                    <select @change="changeHealthSupportFormId(item)" class="form-select" v-if="item.health_support_form.support_form_div == codeStatusSupportA" v-model="item.health_support_form_id">
                                        <option v-for="item in continueSupportFormA" :value="item.id" :key="item.id+index">{{item.support_form_name_disp_01}}</option>
                                    </select>
                                    <select @change="changeHealthSupportFormId(item)" class="form-select" v-if="item.health_support_form.support_form_div == codeStatusSupportB" v-model="item.health_support_form_id">
                                        <option v-for="item in continueSupportFormB" :value="item.id" :key="item.id+index">{{item.support_form_name_disp_01}}</option>
                                    </select>
                                    <select @change="changeHealthSupportFormId(item)" class="form-select" v-if="item.health_support_form.support_form_div == codeStatusSupportContinuing" v-model="item.health_support_form_id">
                                        <option v-for="item in continueSupportForm" :value="item.id" :key="item.id+index">{{item.support_form_name_disp_01}}</option>
                                    </select>
                                    <span class="text-danger">{{ item.messErrorHealthSupportFormId }}</span>
                                </div>
                                <!-- 支援介入量 -->
                                <div class="col-3 d-flex align-items-center">
                                    <label for="implement" class="text-nowrap mr-2">実施({{ item.health_support_form.support_amount_unit }})</label>
                                    <input v-model="item.scheduled_support_amount" @keyup="handlePoint(item, index)" :disabled="item.health_support_form.support_amount_fix_flg" type="text" class="form-control" id="implement">
                                    <span class="text-danger">{{ item.messErrorScheduledSupportAmount }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <!-- 中間評価フラグ -->
                                <div class="col-3 d-flex align-items-center">
                                    <div class="form-check">
                                        <input v-model="item.mid_eval_flg" type="checkbox" class="form-check-input" :id="index+'intermediate-evaluation'">
                                        <label :for="index+'intermediate-evaluation'" class="form-check-label">中間評価</label>
                                    </div>
                                </div>
                                <!-- 基準ポイント・最低介入量 -->
                                <div class="col-3">
                                    <p>基準ポイント：{{ item.health_support_form.basis_point }} pt</p>
                                    <p>最低介入量：{{ item.health_support_form.min_support_amount }}{{ item.health_support_form.support_amount_unit }}</p>
                                    <!-- <p>最大有効ポイント：{{ item.health_support_form.max_point }} pt</p> -->
                                </div>
                                <!-- 支援ポイント -->
                                <div class="col-3 d-flex align-items-center">
                                    <label for="point" class="text-nowrap mr-2">プロセス評価ポイント</label>
                                    <input v-model="item.scheduled_support_point"  @keyup="calcSummary()" type="text" class="form-control" id="point">
                                    <span class="text-danger">{{ item.messErrorScheduledSupportPoint }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block mt-2">
                        <div class="mt-3 title-block d-flex justify-content-between bg-final-support bordered p-2">
                            <div class="title-name">
                                最終評価
                            </div>
                        </div>
                        <div class="content-block bordered no-border-top p-2">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <label for="scheduled-time" class="text-nowrap mr-2">予定時期</label>
                                    <select v-model="dataEdit.final_eval_term" id="scheduled-time" class="form-select">
                                        <option v-for="(text, key) in intendDateList" :value="key" :key="key">{{text}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-between flex-row flex-nowrap align-items-center">
                    <button @click="hide" type="button" class="btn btn-secondary">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    キャンセル
                </button>
                <button @click="showDialogSave = true" type="button" class="btn btn-primary">
                    <i class="fa fa-database" aria-hidden="true"></i>
                    保存
                </button>
                </div>
            </div>
            <Dialog :maxWidth="600" :isShow="showDialogSave">
                <v-card>
                    <v-container>
                        <v-card-title>保存</v-card-title>
                        <div class="m-3">保存処理を実行します。よろしいですか？</div>
                        <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                            <button type="button" class="btn btn-secondary" @click="showDialogSave = false">キャンセル</button>
                            <button @click="save()" type="button" class="btn btn-primary float-right">OK</button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
            <Dialog v-if="isPageMode_Edit()" :maxWidth="600" :isShow="showDialogEditDelete">
                <v-card>
                    <v-container>
                        <v-card-title>削除</v-card-title>
                        <div class="m-3">削除処理を実行します。よろしいですか？</div>
                        <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                            <button type="button" class="btn btn-secondary" @click="showDialogEditDelete = false">キャンセル</button>
                            <button @click="deleteSupportPattern" type="button" class="btn btn-primary float-right">OK</button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
        </div>
    </v-navigation-drawer>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { TOAST_TIME_OUT, ENVS } from "@/common/constants";
import patternManagement from "@/services/api/patternManagement";
import mixins from "@/mixins";
import _ from "lodash";
import Sheet from "@/components/common/Sheet";
import Dialog from "@/components/common/Dialog";

export default {
    name: "Support-Pattern-Detail",
    mixins: [mixins],
    components: {
        Sheet,
        Dialog
    },
    props: {
        pageMode: null, // "NEW":新規、"EDIT":編集
        dataDetail: null
    },
    data() {
        return {
            page_mode: "NEW",
            showDialogEditDelete: false,
            showDialogSave: false,
            code_form_support: [
                {
                    code: this.codeStatusSupportA,
                    name: "支援A (第3期)"
                },
                {
                    code: this.codeStatusSupportB,
                    name: "支援B (第3期)"
                },
                {
                    code: this.codeStatusSupportContinuing,
                    name: "支援 (第4期)"
                },
            ],
            continueSupportFormA: [],
            continueSupportFormB: [],
            continueSupportForm: [],
            intendDateList: [],
            supportPatternSummary: {
                supportA: {
                    count: 0,
                    point: 0,
                },
                supportB: {
                    count: 0,
                    point: 0,
                },
                support: {
                    count: 0,
                    point: 0,
                }
            },
            dataEdit: {
                created_at: null,
                created_by: null,
                disable_flg: 0,
                disp_seq: 0,
                final_eval_term: null,
                get_created_by: null,
                get_updated_by: null,
                health_support_pattern_item: [], // 支援パターン項目
                support_pattern_desc: null,
                support_pattern_name: null,
                plan_support_point: 0,
                requried_support_point: ENVS.DEFAULT_REQED_SUPPORT_POINT,
                updated_at: null,
                updated_by: null,
            },
            isUpdate: false,
            errors: {
                disp_seq: null,
                support_pattern_name: null,
                support_pattern_desc: null,
                requried_support_point: null,
                health_support_pattern_item: null // 支援パターン項目
            },
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
            supportPointCopy: null,
        }
    },
    watch: {
        dataDetail() {
            this.getDetail(this.dataDetail);
        },
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },

    async created() {
        this.page_mode = this.pageMode;
        
        // 期間マスタ
        await patternManagement.getIntendDateList().then(res => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.intendDateList = res.data.data;
            this.dataEdit.final_eval_term = Object.keys(this.intendDateList)[0];
        });
        
        // 支援A 支援形態リスト
        await patternManagement.getContinueSupportFormList(this.codeStatusSupportA).then(res => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            this.continueSupportFormA = res.data.data;
        });

        // 支援B 支援形態リスト
        await patternManagement.getContinueSupportFormList(this.codeStatusSupportB).then(res => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            this.continueSupportFormB = res.data.data;
        });

        // 支援 (第４期)
        await patternManagement.getContinueSupportFormList(this.codeStatusSupportContinuing).then(res => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            this.continueSupportForm = res.data.data;
        });

        if (this.dataDetail) {
            await this.getDetail(this.dataDetail);
            if (this.isPageMode_New()) {
                delete this.dataEdit.id;
                delete this.dataEdit.created_by;
                delete this.dataEdit.updated_by;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "common/getUser"
        }),
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 詳細情報設定
         * @param {*} dataDetail 
         */
        getDetail(dataDetail) {
            this.dataEdit = { ...dataDetail };

            // 支援パターン項目
            this.dataEdit.health_support_pattern_item = [];

            if (dataDetail.health_support_pattern_item) {
                dataDetail.health_support_pattern_item.map((val, index) => {
                    this.add(index, true);
                    const item = this.dataEdit.health_support_pattern_item[index];
                    item.health_support_form_id = val.health_support_form_id;
                    this.changeHealthSupportFormId(item);

                    item.scheduled_support_term = val.scheduled_support_term;
                    item.mid_eval_flg =val.mid_eval_flg;
                    item.scheduled_support_amount = val.scheduled_support_amount ?? 0;
                    item.scheduled_support_point = val.scheduled_support_point;
                });
                this.calcSummary();
            }

            if (this.isPageMode_New()) {
                this.dataEdit.disp_seq = 0;
                this.dataEdit.disable_flg = 0;
                this.dataEdit.created_at = "";
                this.dataEdit.created_by = null;
                this.dataEdit.updated_at = "";
                this.dataEdit.updated_by = null;
            }
        },

        /**
         * バリデーション (支援形態)
         */
        validate(){
            // 各支援パターン項目に対する入力チェック.
            this.dataEdit.health_support_pattern_item.map(x => {

                x.messErrorHealthSupportFormId = null;
                x.messErrorScheduledSupportAmount = null;
                x.messErrorScheduledSupportPoint = null;

                if(!x.health_support_form_id){
                    x.messErrorHealthSupportFormId = "支援形態を選択してください。";
                }

                if(x.scheduled_support_amount === "" || x.scheduled_support_amount === null){
                    x.messErrorScheduledSupportAmount = "支援介入量を入力してください。";
                }else if(x.health_support_form_id) {
                    const dataOptionHealthSupport = this.findContinuousSupportForm(x.health_support_form_id);
                    if(x.scheduled_support_amount && 
                      (x.scheduled_support_amount < dataOptionHealthSupport?.min_support_amount || x.scheduled_support_amount > 9999)){
                        x.messErrorScheduledSupportAmount = `支援介入量は[${dataOptionHealthSupport?.min_support_amount}〜9999]の範囲で入力してください。`;
                    }
                }

                if(x.scheduled_support_point === "" || x.scheduled_support_amount === null){
                    x.messErrorScheduledSupportPoint = "支援ポイントを入力してください。";
                }else if(x.scheduled_support_point && (x.scheduled_support_point < 0 || x.scheduled_support_point > 9999)){
                    x.messErrorScheduledSupportPoint = "支援ポイントは[0〜9999]の範囲で入力してください。";
                }
            });

            return this.dataEdit.health_support_pattern_item.filter(
                x => x.messErrorHealthSupportFormId != null || 
                x.messErrorScheduledSupportAmount != null || 
                x.messErrorScheduledSupportPoint != null).length == 0;
        },

        /**
         * バリデーション
         */
        checkValidate() {
            this.errors.disp_seq = null;
            this.errors.support_pattern_name = null;
            this.errors.requried_support_point = null;
            this.errors.support_pattern_desc = null;

            if (this.dataEdit.disp_seq) {
                if (!String(this.dataEdit.disp_seq).match(/^[0-9]+$/)) {
                    this.errors.disp_seq = "表示順は数字で入力してください。";
                } else if (this.dataEdit.disp_seq > 999 || this.dataEdit.disp_seq < 0) {
                    this.errors.disp_seq = "表示順は[0〜999]の範囲で入力してください。";
                }
            } else {
                if (this.dataEdit.disp_seq !== 0) {
                    this.errors.disp_seq = "表示順を入力してください。";
                }
            }

            if (!this.dataEdit.support_pattern_name && this.dataEdit.support_pattern_name !== 0) {
                this.errors.support_pattern_name = "支援パターン名を入力してください。";
            } else if (String(this.dataEdit.support_pattern_name).length > 60) {
                this.errors.support_pattern_name = "支援パターン名は60文字以下で入力してください。";
            }

            if (String(this.dataEdit.support_pattern_desc).length > 500) {
                this.errors.support_pattern_desc = "支援パターン説明は500文字以下で入力してください。";
            }

            if (this.dataEdit.requried_support_point) {
                if (!String(this.dataEdit.requried_support_point).match(/^[0-9]+$/)) {
                    this.errors.requried_support_point = "支援満了ポイントを入力してください。";
                } else if (this.dataEdit.requried_support_point > 9999 || this.dataEdit.requried_support_point < 0) {
                    this.errors.requried_support_point = "支援満了ポイントは[0〜9999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.requried_support_point !== 0){
                    this.errors.requried_support_point = "支援満了ポイントを入力してください。";
                }
            }
        },

        /**
         * 保存
         */
        save() {
            this.showDialogSave = false;

            this.checkValidate();
            let checkValidate = true;
            for (const [key, value] of Object.entries(this.errors)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            const checkValidateSupportPatternItems = this.validate();

            if (checkValidate && checkValidateSupportPatternItems) {
                const data = {};
                data.support_pattern_name = this.dataEdit.support_pattern_name;
                data.final_eval_term = this.dataEdit.final_eval_term;
                data.support_pattern_desc = this.dataEdit.support_pattern_desc;
                data.requried_support_point = this.dataEdit.requried_support_point;
                data.plan_support_point = this.dataEdit.plan_support_point;
                data.disp_seq = this.dataEdit.disp_seq;
                data.disable_flg = this.dataEdit.disable_flg;
                data.health_support_pattern_items = [];

                let count_mid_eval_flg = 0;
                this.dataEdit.health_support_pattern_item.map((x, index) => {
                    if (x.mid_eval_flg) {
                        count_mid_eval_flg++;
                    }
                    data.health_support_pattern_items.push({
                        sequence: index + 1,
                        scheduled_support_term: x.scheduled_support_term,
                        scheduled_support_amount: parseInt(x.scheduled_support_amount),
                        scheduled_support_point: x.scheduled_support_point,
                        mid_eval_flg: x.mid_eval_flg,
                        health_support_form_id: x.health_support_form_id,
                        disable_flg: x.disable_flg
                    });
                })

                if (count_mid_eval_flg > 1) {
                    this.setIsLoading(false)
                    this.$toast.open({
                        message: "[E0701]入力エラー <br>中間評価は複数の支援に対して設定できません。",
                        type: "error",
                    });
                    return;
                }

                this.setIsLoading(true);

                if (this.isPageMode_New()) {
                    this.storeSupportPattern(data);
                } else {
                    this.updateSupportPattern(data);
                }
            }
        },

        /**
         * 新規保存
         */
        async storeSupportPattern(data) {
            patternManagement.savePattern(data)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const {data, status} = res;
                    this.setIsLoading(false)
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: res.error.message,
                            type: "error",
                        });
                    } else {
                        this.$toast.open({
                            message: "支援パターンの登録が完了しました。",
                            type: "success",
                        });

                        data.data.healthSupportPattern.health_support_pattern_item =
                            data.data.healthSupportPatternItemList;
                        
                        this.page_mode = "EDIT";
                        this.isUpdate = true;
                        this.getDetail(data.data.healthSupportPattern);
                    }
                })
        },

        /**
         * 更新
         */
        async updateSupportPattern(data) {
            patternManagement.editPattern(this.dataEdit.id, data)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const {data, status} = res;
                    this.setIsLoading(false)
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: res.error.message,
                            type: "error",
                        });
                    } else {
                        this.$toast.open({
                            message: "支援パターンの登録が完了しました。",
                            type: "success",
                        });

                        data.data.healthSupportPattern.health_support_pattern_item =
                            data.data.healthSupportPatternItemList;
                        
                        this.isUpdate = true;
                        this.getDetail(data.data.healthSupportPattern);
                    }
                })
        },

        /**
         * データ削除
         */
        deleteSupportPattern() {
            this.setIsLoading(true)
            this.showDialogEditDelete = false;
            patternManagement.deletePattern(this.dataEdit.id)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const { data, status } = res;
                    this.setIsLoading(false);
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: "入力エラー <br>他データから参照されているため、削除できません。",
                            type: "error",
                        });
                    } else {
                        this.$toast.open({
                            message: "支援パターンの削除が完了しました。",
                            type: "success",
                        });
                        this.isUpdate = true;
                        this.hide();
                    }
                })
        },

        /**
         * 支援パターン項目削除.
         * @param {*} item 
         * @param {*} index 
         */
        deleteSupportPatternItem(item, index){
            // this.$toast.open({
            //     message: "支援パターンの削除が完了しました。",
            //     type: "success",
            // });
            this.dataEdit.health_support_pattern_item.splice(index,1);
        },

        /**
         * 後続支援追加
         */
        add(insertIndex, createOnly = false){
            const data = {
                health_support_form: {
                    support_form_div: null, // 支援形態区分
                    min_support_amount: 0, // 最低介入量
                    support_amount_unit: null, // 単位
                    basis_support_amount: 0, // 基準介入量
                    basis_point: 0, // 基準ポイント
                    max_point: 0, // 最大有効ポイント
                    support_amount_fix_flg: false, // 介入量固定フラグ
                },
                disable_flg: 0,
                health_support_pattern_id: 0, // 支援パターンID
                id: null, // ID
                health_support_form_id: this.continueSupportFormA[0].id, // 支援A 支援形態の先頭
                mid_eval_flg: 0, // 中間評価フラグ
                scheduled_support_amount: 0, // 予定介入量
                scheduled_support_point: 0, // 予定ポイント
                scheduled_support_term: Object.keys(this.intendDateList)[0], // 支援予定時期
                messErrorHealthSupportFormId: null,
                messErrorScheduledSupportAmount: null,
                messErrorScheduledSupportPoint: null,
            };

            // 支援パターン項目リスト.
            this.dataEdit.health_support_pattern_item.splice(insertIndex, 0, data);

            if (createOnly) return;

            this.changeHealthSupportFormId(data);
        },

        /**
         * 支援パターン項目初期化.
         */
        initializeSupportPatternItem(item) {
            switch(item.health_support_form.support_form_div) {
            // (3期)支援A
            case this.codeStatusSupportA: {
                item.health_support_form_id = this.continueSupportFormA[0].id;
                break;
            }
            // (3期)支援B
            case this.codeStatusSupportB: {
                item.health_support_form_id = this.continueSupportFormB[0].id;
                break;
            }
            // (4期)支援
            case this.codeStatusSupportContinuing: {
                item.health_support_form_id = this.continueSupportForm[0].id;
                break;
            }
            }
            item.mid_eval_flg = 0;
            item.scheduled_support_amount = 0;
            item.scheduled_support_point = 0;
            item.scheduled_support_term = Object.keys(this.intendDateList)[0];
            
            item.health_support_form.support_form_div = null;
            item.health_support_form.min_support_amount = 0;
            item.health_support_form.support_amount_unit = null;
            item.health_support_form.basis_support_amount = 0;
            item.health_support_form.basis_point = 0;
            item.health_support_form.max_point = 0;
            item.health_support_form.support_amount_fix_flg = false;

            item.messErrorHealthSupportFormId = null;
            item.messErrorScheduledSupportAmount = null;
            item.messErrorScheduledSupportPoint = null;
        },

        /**
         * 支援形態区分 変更時
         * 
         * @param {*} item 
         */
        handleChangeSupportFormDiv(item) {
            this.initializeSupportPatternItem(item);
            this.changeHealthSupportFormId(item);
        },

        /**
         * 支援形態 変更
         * @param {*} item 
         */
        changeHealthSupportFormId(item){

            // 支援形態が設定されている場合. 
            if(item.health_support_form_id && item.health_support_form_id != undefined) {
                // IDを元に支援形態マスタ取得.
                const dataOptionHealthSupport = this.findContinuousSupportForm(item.health_support_form_id);

                // 支援形態 最低介入量
                item.health_support_form.support_form_div = dataOptionHealthSupport.support_form_div;
                // 支援形態 最低介入量
                item.health_support_form.min_support_amount = dataOptionHealthSupport.min_support_amount;
                // 支援形態 単位
                item.health_support_form.support_amount_unit = dataOptionHealthSupport.support_amount_unit;
                // 支援形態 基準ポイント
                item.health_support_form.basis_point = dataOptionHealthSupport.basis_point;
                // 支援形態 基準介入量
                item.health_support_form.basis_support_amount = dataOptionHealthSupport.basis_support_amount;
                // 支援形態 最大有効ポイント
                item.health_support_form.max_point = dataOptionHealthSupport.max_point;
                // 支援形態 介入量固定フラグ
                item.health_support_form.support_amount_fix_flg = dataOptionHealthSupport.support_amount_fix_flg == 1 ? true : false;

                // 予定介入量: 最低介入量
                item.scheduled_support_amount = dataOptionHealthSupport?.min_support_amount ?? 0;
                // 予定支援ポイント: 算出
                item.scheduled_support_point = this.calcSupportPoint(item.health_support_form, item.scheduled_support_amount);

                this.calcSummary();
            }
        },

        /**
         * 支援ポイント算出
         * 
         * @param {*} supportForm 
         * @param {*} amount 
         */
        calcSupportPoint(supportForm, amount) {
            if(supportForm == null) return 0;
            // 基準介入量
            const basis_support_amount = supportForm?.basis_support_amount || 1;
            // 基準ポイント
            const basis_point = supportForm?.basis_point || 1;
            // ポイント上限
            const max_point = supportForm?.max_point || 0;
            // 対象セグメントの予定支援ポイントの更新
            if (basis_support_amount === 0) {
                return 0;
            } else{
                // 基準をもとに切り捨てたポイントの更新
                return Math.min(parseInt(amount / basis_support_amount) * basis_point, max_point);
            }
        },

        /**
         * 集計計算.
         */
        calcSummary() {
            this.supportPatternSummary.supportA.count = 0;
            this.supportPatternSummary.supportA.point = 0;
            this.supportPatternSummary.supportB.count = 0;
            this.supportPatternSummary.supportB.point = 0;
            this.supportPatternSummary.support.count = 0;
            this.supportPatternSummary.support.point = 0;
            this.dataEdit.plan_support_point = 0;

            // foreach内でthisを参照するため.
            const self = this;
            this.dataEdit.health_support_pattern_item.forEach(function(item) {
                if (self.checkSupportA(item)) {
                    self.supportPatternSummary.supportA.count++;
                    const v = parseInt(item.scheduled_support_point);
                    self.supportPatternSummary.supportA.point += v ? v : 0;
                }
                if (self.checkSupportB(item)) {
                    self.supportPatternSummary.supportB.count++;
                    const v = parseInt(item.scheduled_support_point);
                    self.supportPatternSummary.supportB.point += v ? v : 0;
                }
                if (self.checkSupport(item)) {
                    self.supportPatternSummary.support.count++;
                    self.supportPatternSummary.support.point += parseInt(item.scheduled_support_point) ?? 0;
                }
            });

            this.dataEdit.plan_support_point =
                this.supportPatternSummary.supportA.point + 
                this.supportPatternSummary.supportB.point +
                this.supportPatternSummary.support.point;
        },

        /**
         * 介入量入力時 ポイント計算
         * @param {*} item 
         * @param {*} index 
         */
        handlePoint(item, index) {
            //　予定介入量が存在する場合.
            if(item.scheduled_support_amount) {
                // 支援形態取得.
                const dataOptionHealthSupport = this.findContinuousSupportForm(item.health_support_form_id);
                item.scheduled_support_point = this.calcSupportPoint(dataOptionHealthSupport, item.scheduled_support_amount);
                this.calcSummary();
            }
        },

        /**
         * 継続支援 支援形態 検索.
         */
        findContinuousSupportForm(health_support_form_id) {
            return [
                ...this.continueSupportFormA,
                ...this.continueSupportFormB,
                ...this.continueSupportForm,
            ].filter(
                (x) => x.id == health_support_form_id
            )[0];
        },

        /**
         * 日付フォーマット
         * @param {*} date 
         */
        formatDate(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },

        /**
         * 閉じる
         */
        hide() {
            this.hiddenAnimation = true;
            $(".tab-eligible-person-information").scrollTop(0);
            if (this.isUpdate) {
                this.$emit("reload", true);
                this.isUpdate = false;
            }
            this.$emit("reload", false);
            this.$store.commit("hideSlide");
        },

        /**
         * 
         */
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },

        /**
         * 
         */
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },

        /**
         * リロード
         */
        reloadDetail(param) {
            if (param == "update") {
                this.isUpdate = true;
            }
            this.hide();
        },

        /**
         * PageMode 新規の場合true
         */
        isPageMode_New() {
            return this.page_mode == "NEW";
        },

        /**
         * PageMode 編集の場合true
         */
        isPageMode_Edit() {
            return this.page_mode == "EDIT";
        }
    }
}
</script>
<style scoped lang="sass">
    @import "../../styles/common"
    .sidebar-show
        animation: show 0.5s ease forwards

    .sidebar-hidden
        animation: hidden 0.5s ease forwards

    @media (max-width: 1025px)
        .navigation
            transform: translate(0%) !important
        .v-navigation-drawer--close
            visibility: unset

    @keyframes hidden
        from
            width: var(widthAnimation)
        to
            width: 0

    @keyframes show
        from
            width: 0
        to
            width: var(widthAnimation)

    .support-pattern-modify
        padding: 10px

    .bordered
        border: 1px solid #333
    .no-border-top
        border-top: none

    .no-radius
        border-radius: unset

    .dropdown-btn
        border-left: 1px solid
        padding: 2px 4px

    .support-pattern-modify
        overflow: hidden

    .tab-eligible-person-information
        max-height: 92vh
        height: 92vh
        overflow-y: scroll
        overflow-x: hidden
</style>
